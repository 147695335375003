import tippy, {delegate} from 'tippy.js'

export default function initTooltips() {
	delegate('body', {
		allowHTML: true,
		animation: 'fade',
		aria: {
			// `null` when interactive is enabled
			content: 'auto', // `aria-*` attribute
			// `true` when interactive is enabled
			expanded: 'auto', // `aria-expanded` attribute
		},
		arrow: true,
		onShow: options => {
			return !!options.props.content
		},
		popperOptions: {
			modifiers: [
				{
					enabled: false,
					name: 'flip',
					// flip: false
					options: {
						// flipBehavior: ['bottom', 'right', 'top']
						fallbackPlacements: ['right', 'top'],
					},
				},
			],
		},
		role: 'dialog',
		target: '.js-tooltip',
	})

	tippy('.js-tooltip-right', {
		allowHTML: true,
		animation: 'fade',
		aria: {
			// `null` when interactive is enabled
			content: 'auto', // `aria-*` attribute
			// `true` when interactive is enabled
			expanded: 'auto', // `aria-expanded` attribute
		},
		arrow: true,
		onShow: options => {
			return !!options.props.content
		},
		placement: 'right-end',
		popperOptions: {
			modifiers: [
				{
					enabled: false,
					name: 'flip',
					options: {fallbackPlacements: ['right', 'top']},
				},
			],
		},
		role: 'dialog',
	})
}
